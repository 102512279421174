import * as React from "react"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import style from './index.module.less'
import { nebulaIcon } from '@/images/experience/index'
import { DetailFaq } from '@/components/Support/index'
import { useIntl } from "react-intl"
const Index = () => {

 const intl = useIntl()

  return (
    <Layout  menuBlack={true}>
      <SEO page="Support" />
      <div className={style.header}>
        <img src={nebulaIcon} />
        <div className={style.title}>Nebula</div>
        <div className={style.desc}>
{intl.formatMessage({ id: "nebula_text", defaultMessage: "Nebula for Nreal Light & Nreal Air users: Download now from the Google Play Store and light up your AR journey today!" })}</div>
      </div>
    

      <DetailFaq type='nebula'/>

    </Layout>
  )
}

export default Index